/* eslint-disable react/function-component-definition */
import { SearchRounded, WorkTwoTone } from "@mui/icons-material";
import { IoRocketSharp } from "react-icons/io5";
import { Link } from "gatsby";
import { node, oneOfType, shape, string } from "prop-types";
import React, { useContext, useEffect } from "react";
import tw from "twin.macro";

import { TilePrim } from "../../components/util-components";

import { PageTitleContext } from "../../utils/pagetitle-provider";
import withRoles from "../../utils/with-roles";
import JimHelmet from "../../components/JimHelmet";

const GridWrapper = tw.div`grid gap-6 grid-cols-1 md:grid-cols-2`;

const TileThis = ({ to, state, title, children }) => (
  <Link to={to} state={state}>
    <TilePrim
      tw="my-0 hover:(shadow-lg) cursor-pointer transition duration-200"
      title={title}
    >
      {children}
    </TilePrim>
  </Link>
);
TileThis.propTypes = {
  to: string.isRequired,
  state: shape(),
  title: oneOfType([node, string]).isRequired,
  children: node.isRequired,
};
TileThis.defaultProps = {
  state: undefined,
};

const Jobs = () => {
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("Jobs"), []);

  return (
    <>
      <JimHelmet path="jobs" />
      <GridWrapper>
        <TileThis
          to="/jobs/find"
          title={
            <div tw="flex items-center">
              <SearchRounded tw="mr-2" />
              Jobs finden
            </div>
          }
        >
          Finde hier deinen nächsten Einsatz.
        </TileThis>
        <TileThis
          to="/jobs/current"
          title={
            <div tw="flex items-center">
              <IoRocketSharp tw="mr-2" />
              Anstehende Einsätze
            </div>
          }
        >
          Hier siehst du deine anstehenden Einsätze.
        </TileThis>
        <TileThis
          to="/jobs/past"
          title={
            <div tw="flex items-center">
              <WorkTwoTone tw="mr-2" />
              Abgeschlossene Einsätze
            </div>
          }
        >
          Hier bekommst du eine Übersicht über deine bereits abgeschlossenen
          Einsätze.
        </TileThis>
      </GridWrapper>
    </>
  );
};

export default withRoles(Jobs, "WORKER");
